import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import hive from '@hiveio/hive-js';
import Swal from 'sweetalert2';
import { storeAppDispatch } from '../../../../GlobalState/Store';
import { setPlayerData, setPlayerLogout } from '../../../../reducers/UserReducer';
import { useHistory } from 'react-router-dom';
import { verifyUserIdentity } from '../../../../sagas/helpers/login/verifyUser';
import { processInventory } from '../../../../sagas/helpers/inventory/processInventory';
import { setPlayerInventory } from '../../../../reducers/InventoryReducer';
import { isLocal, isTestnet } from '../../../../constants/envs';
import { blacklistedUsers, multiTabCheckAfterLogin, usernamesChecks } from '../../../../generalUtils/secure';
import { setFreeTrial } from '../../utils/freeTrial';
import { fetchHiveNfts } from '../../../../sagas/helpers/inventory/hiveNfts';
import { isFeatureEnabled } from '../../../../config/featureFlags';
import { loadStorage } from '../../../../components/Menu/LeftMenu/storage/init/loadStorage';
import { fetchUserDataOnly, setUrlParamApiCalls } from '../../../../helpers/client';
import { LINKS, ICONS } from './config';
import { navigatePostLogin } from '../Shared/utils/navigation';


export const HiveLoginPanel = () => {
  const UserState = useSelector((store) => store.user);
  const { chainLogin } = UserState;

  const [hiveUsername, setHiveUsername] = useState(localStorage.getItem('hiveUsername') || '');
  const locationHistory = useHistory();

  const hiveLoginSteps = async ({ username, message, response }) => {
    // # BLACKLIST CHECK
    const blacklistedUserData = blacklistedUsers[username];
    if (blacklistedUserData) {
      isTestnet && console.log('401 reason: ', blacklistedUserData);
      Swal.fire({ text: `Uh oh, there seems to be a problem with your account.\nPlease reach out to support on Discord.` });
      return;
    } else { isLocal && console.log('u-ok', { blacklistedUsers, u: username }); }

    // # VERIFICATION IN BACKEND
    try {
      await verifyUserIdentity({ username, message, signed_message: response.result, chainLogin });
    } catch (err) {
      handleHiveLoginError('Caught error during Hive login. Please try later.');
      return;
    }
    setFreeTrial({ value: false, from: 'login-btn' });

    // ## LOGGED-IN USER PUSH
    storeAppDispatch(setPlayerData({
      name: username,
      isLogged: true,
    }));
    localStorage.setItem('chainLogin', chainLogin);

    // ## Anti-Cheat
    usernamesChecks(username);

    // ## FETCH HIVE NFTS
    const { hiveNftsArr /*, teacher*/ } = await fetchHiveNfts({ hiveUsername: username });
    // UI next login wait
    localStorage[hiveNftsArr.length ? 'setItem' : 'removeItem']('hivenfts', true);

    const { linkedAccounts, err: errDataFetch } = await fetchUserDataOnly({ account: username, chain: chainLogin });
    isLocal && console.debug('cross-chain account linked:', { linkedAccounts, errDataFetch });
    if (errDataFetch) {
      Swal.fire({ text: `Something went wrong fetching your data. Please try again.` });
      storeAppDispatch(setPlayerLogout());
      return;
    }

    // # FETCH WAX {INVENTORY}
    const { wax: linkedWaxAccount, btc: linkedBtcAddress } = linkedAccounts || {};
    isLocal && console.log('{hl} fetching inventory for Wax linked user', linkedWaxAccount);
    let csNfts = [];
    let hotNfts = {};
    if (linkedWaxAccount) {
      // eslint-disable-next-line no-unused-vars
      const inventoryResponse = await processInventory({ username: linkedWaxAccount });
      const { err: errWaxInventory, scholarship: _notSupported_ } = inventoryResponse;
      csNfts = inventoryResponse.csNfts;
      hotNfts = inventoryResponse.hotNfts;
      if (errWaxInventory) {
        console.error('Error fetching linked wax inventory for hive user', errWaxInventory);
        storeAppDispatch(setPlayerLogout());
        return;
      }
      localStorage[csNfts.length === 0 ? 'removeItem' : 'setItem']('waxnfts', true);
      // let kitNft;
      // const hasNoNfts = csNfts.length === 0 && Object.keys(hotNfts).length === 0;
      // if (hasNoNfts) {
      //   kitNft = { [MINIMUM_REQUIREMENTS_INFO]: '' }; // HACK @@
      // }
    }

    const currentState = {
      csAssets: { csNfts, hotNfts },
      hive: {
        nfts: hiveNftsArr, username /*, teacher*/,
        linkedWaxAccount, linkedBtcAddress, // linked chains
      },
      scholarship: null, chainLogin: UserState.chainLogin,
    };
    storeAppDispatch(setPlayerInventory(currentState));

    // ## Routing
    const hasNfts = csNfts.length + hiveNftsArr.length;
    navigatePostLogin({ locationHistory, hasNfts });

    // ## Fetch Balances
    // isLocal && console.log('{hl} fetching boom balance');
    // const boomAmount = await fetchBoomBalance(UserService.authName);
    // const savingsAmount = await Promise.resolve(0); // TEMP for savings @@ @@ @@ @@
    // storeAppDispatch(setPlayerWaxTokensBalances({
    //   tokens: { boom: boomAmount },
    //   stakedSavings: { boom: savingsAmount }
    // }));
    // // Whitelist check (TESTNET only)
    // isLocal && console.log('{hl} checking whitelist items');
    // const missingItem = await verifyAccessAllowed(UserService.authName, csNfts); // IMPROVEMENT: just check the NFTs just fetched above !!!
    // if (missingItem) {
    //   const isOk = window.confirm(`${UserService.authName} the following item is required to access this version of the game: ${(missingItem || '').replace(/_/g, ' ')}\n\nUnless it's a new mint, keep in mind the 12 hour cooldown.`);
    //   if (isOk)
    //     window.open('https://wax.atomichub.io/market?collection_name=crypto4shots&order=desc&schema_name=early.access&sort=created&symbol=WAX', '_blank');
    //   storeAppDispatch(setPlayerLogout());
    //   !isLocal && window.location.reload();
    // }

    // # MULTI SESSION CHECKS
    multiTabCheckAfterLogin();

    // # LOAD STORAGE 
    loadStorage({ user: username });
    isLocal && console.log('{hl} all done');
  };

  const triggerKeychainLogin = () => {
    const message = `${hiveUsername}_${Date.now()}`;
    window.hive_keychain.requestSignBuffer(
      hiveUsername,
      message,
      'Posting',
      async (response) => {
        console.log('Keychain requestSignBuffer:', response);
        if (response.error) {
          const errMsg = 'Keychain signing failed';
          console.error(errMsg, response.error);
          handleHiveLoginError(errMsg);
          toast.error(errMsg);
          return;
        }
        let username = get(response, 'data.username');
        console.log('response data', response.data);
        if (username !== hiveUsername) {
          const errMsg = 'Username mismatch';
          console.error(errMsg, username, response.error);
          handleHiveLoginError(errMsg);
          toast.error(errMsg);
          return;
        }
        // if (!isFeatureEnabled('HIVE')) {
        //   const errMsg = 'You gotta wait until Sunday! 🚀';
        //   handleHiveLoginError(errMsg);
        //   return;
        // }
        toast.success('Keychain login OK');
        hiveLoginSteps({ username, message, response });
      }
    );
  };

  const handleLogin = (event) => {
    event.preventDefault();
    setIsLoadingHive(true);
    if (hiveUsername.endsWith('.wam')) { // wax username check
      handleHiveLoginError(`Invalid Hive username, this is not the Wax login.`);
      return;
    }
    if (window.hive_keychain) {
      window.hive_keychain.requestHandshake(() => {
        try {
          console.log('handshake success!');
          toast.success('Keychain handshaked');
          localStorage.setItem('chainLogin', UserState.chainLogin);
          setUrlParamApiCalls({ chain: UserState.chainLogin });
          // input validation (username rules only)
          let error = hive.utils.validateAccountName(hiveUsername);
          if (error) {
            console.error('Invalid hive name. Reason:', error);
            handleHiveLoginError(`Invalid username. ${error}`);
            return;
          }
          triggerKeychainLogin();
        } catch (err) { console.error('Caught after handshake:', err); }
      });
    } else {
      const errMsg = <span>Keychain handshake failed! Install the&nbsp;
        <a
          id="keychain-installer"
          href={LINKS.KEYCHAIN_INSTALL}
          target="_blank" rel="noreferrer"
        >
          Hive Keychain extension
        </a>
      </span>;
      handleHiveLoginError(errMsg);
      toast.error('No Hive Keychain found');
    }
  };

  const [hiveLoginError, setHiveLoginError] = useState('');
  const [isLoadingHive, setIsLoadingHive] = useState(false);

  const handleHiveLoginError = (errMsg) => {
    setHiveLoginError(errMsg);
    setIsLoadingHive(false);
  };

  return (
    <div>
      <div id="login-wrapper" className="text-center">
        <form onSubmit={handleLogin}>
          <input
            placeholder="Your hive username"
            id="hive-username-input"
            value={hiveUsername}
            onChange={(input) => setHiveUsername(input.target.value)}
          ></input>
          <button
            id="login-btn"
            type="submit"
            className={`${UserState.acceptedTac ? 'active' : 'disabled'} hive-login`}
            disabled={!UserState.acceptedTac || isLoadingHive}
          >
            <img src={ICONS.DOOR_LOGIN} alt="Loggin" width="24" /> &nbsp;
            {isLoadingHive
              ? <i className="fa fa-spinner fa-spin" style={{ fontSize: '24px' }}></i>
              : <span>LOGIN</span>
            }
          </button>
          {hiveLoginError && <p id="hive-login-error">{hiveLoginError}</p>}
        </form>
      </div>
      <h6 id="sign-up" className="mt-4 text-center">
        Don't have a HIVE account? &nbsp;
        <a
          href={LINKS.HIVE_SIGNUP}
          target="_blank"
          rel="noopener noreferrer"
        >
          <sub id="signup-cta">Sign Up</sub>
        </a>
      </h6>
    </div>
  );
};
