import React, { useState } from 'react';
import SciFiWarning from '../../../_embedAssets/sci-fi-warning.png';
import { checkBrowserType } from '../../../generalUtils/browser/browserType';
import './SuggestBrowser.scss';


const { isBrave, choseIgnoreBrave } = checkBrowserType();

export const SuggestBrave = () => {
  const [shouldDisplay, setShouldDisplay] = useState(true);

  const handleClose = () => {
    localStorage.setItem('dismissBrave', 1);
    setShouldDisplay(false);
  };

  if (isBrave || choseIgnoreBrave || !shouldDisplay) return null;

  return (
    <div className="suggest-brave">
      <h4>
        <img src={SciFiWarning} alt="Warning" className="warning-icon" />
        <span>
          You're not using {' '}
          <a
            href="https://brave.com/download/"
            target="_blank"
            rel="noreferrer"
          >
            Brave Browser
          </a>
          .
          <br/>Equip it now if you want to boost your frame rate to warp speed!
        </span>
        <br />
        <br />
        &nbsp; &nbsp; &nbsp; If you still experience lag, close heavy apps and adjust the in-game graphics
        settings.
        <button onClick={handleClose} className="close-button">
          ✖
        </button>
      </h4>
    </div>
  );
};
